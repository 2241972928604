@import "../../Assets/Styles/typography-mixins.scss";

.login,
.login-with-unique-id {
  .form-card {
    display: flex;
    flex-direction: column;
    padding: 40px 32px;
    row-gap: 56px;
    border-radius: var(--rounded-lg);
    background-color: var(--bg-secondary);

    .username-badge {
      white-space: nowrap;
    }

    .resend-otp-button {
      @include text-lg-medium;
      color: var(--text-primary);
      text-decoration: underline;
    }
  }
}
