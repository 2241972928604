@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

.renting {
  .rent-details,
  .my-return-on-rent,
  .credit-builder,
  .rent-tracker-item,
  .rent-calendar {
    padding: 24px;
    @include styled-card;
  }

  .pay-rent-with-stake {
    @include styled-card;

    .content {
      padding: 24px;
    }
  }

  .rent-details {
    position: relative;

    .content {
      height: 100%;
      width: calc(100% - 230px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
    }

    .detail {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .detail-name {
        @include text-base-regular;
        color: var(--medium-grey);
      }

      .detail-value {
        @include text-lg-medium;
        color: var(--charcoal);
      }
    }

    .illustration {
      height: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .my-return-on-rent {
    .my-return-on-rent-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .item-content {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .item-name {
          @include text-base-regular;
          color: var(--medium-grey);
        }

        .item-value {
          @include text-lg-medium;
          color: var(--charcoal);
        }
      }

      .item-help-toggle svg {
        height: 32px;
        width: 32px;

        path {
          stroke: var(--charcoal);
        }
      }

      + .my-return-on-rent-item {
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid var(--HR);
      }
    }
  }

  .pay-rent-with-stake {
    overflow: hidden;

    .person-with-key-illustration {
      width: 100%;
      height: 305px;
      object-fit: cover;
      object-position: 50% 85%;
    }

    .content {
      .title {
        @include text-2xl-semibold;
        color: var(--charcoal);
      }

      .description {
        display: block;
        @include text-base-regular;
        color: var(--charcoal);
      }

      .steps-title {
        @include text-xl-medium;
        color: var(--charcoal);
      }

      .steps {
        display: flex;
        flex-direction: column;
        row-gap: 6px;

        .step {
          display: flex;
          column-gap: 12px;
          @include text-base-regular;
          color: var(--charcoal);

          .symbol {
            font-size: 16px;
          }
        }
      }
    }
  }

  .credit-builder {
    position: relative;
    overflow: hidden;

    img.coin,
    img.sparkle {
      position: absolute;
    }

    img.coin {
      height: 65px;
      top: 0;
      right: 0;
    }

    img.sparkle {
      height: 50px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    .title,
    .description {
      color: var(--charcoal);
      text-align: center;
    }

    .title {
      @include text-2xl-semibold;
    }

    .description {
      display: block;
      max-width: 472px;
      margin: 0 auto;
      @include text-base-regular;
    }
  }
}
