@import "../../Assets/Styles/mixins.scss";

@include mq(475, max) {
  .stake-pay-3 {
    .payment-card {
      padding: 16px 12px;
    }

    &.select .card-section-wrapper {
      .card-section {
        .rent-details {
          padding: 32px 12px;
        }

        .content {
          padding: 24px 12px;
        }
      }
    }
  }
}
