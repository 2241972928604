img.stake-card-front-side {
  width: 100%;
  filter: drop-shadow(0px 3.667px 15.889px rgba(0, 0, 0, 0.11));
}

img.download-stake-mobile-app-qrcode {
  width: min(calc(100% - 64px), 340px);
}

img.coin-image {
  height: 80px;
}

img.astra-icon {
  height: 32px;
}

img.credit-builder-opt-in {
  height: 400px;
  max-height: calc(100vh - 272px - 64px);
  object-fit: cover;
  object-position: 50% 20%;
}

img.credit-builder-opt-out {
  height: 475px;
  max-height: calc(100vh - 180px - 64px);
  object-fit: cover;
  object-position: 50% 50%;
}

.modal-image-carousel {
  display: flex;
  flex-direction: column;
}

.modal-image.svd-single-card {
  @include flex-center;
  background-color: #eef7f4;

  img {
    width: 75% !important;
    height: 250px;
    object-fit: cover;
    object-position: 50% 55%;
  }
}

img.person-with-key-illustration {
  height: 250px;
  object-fit: cover;
  object-position: 50% 85%;
}

@include mq(629, max) {
  img.credit-builder-opt-in,
  img.credit-builder-opt-out {
    height: 300px;
  }

  img.credit-builder-opt-in {
    flex: 1 0;
    max-height: calc(100vh - 272px);
  }

  img.credit-builder-opt-out {
    max-height: calc(100vh - 156px);
  }

  .modal-image.svd-single-card {
    img {
      width: 100%;
      height: 350px;
      object-position: 50% 60%;
    }
  }

  img.person-with-key-illustration {
    height: 300px;
    object-position: 60% 85%;
  }
}
