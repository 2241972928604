@keyframes slide-bottom-to-top-fade-in {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes expand-animation {
  0% {
    height: var(--initial-height);
  }
  100% {
    height: var(--content-height);
  }
}
