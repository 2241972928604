@import "./mixins.scss";
@import "./typography-mixins.scss";

#portal-target-node .notistack-SnackbarContainer {
  width: 100%;
  max-width: 100%;
  left: 0;
  transform: none;

  & > * {
    width: 100%;
  }
}

.generic-toast {
  min-height: 48px;
  width: var(--large-screen-max-content-width);
  border-radius: var(--rounded-md);
  padding: 8px 16px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 16px;

  &.success {
    background-color: var(--success-green);
  }

  &.error {
    background-color: var(--error-red);
  }

  &.warning {
    background-color: var(--stake-yellow);
  }

  &.info {
    background-color: var(--stake-blue);
  }

  .variant-icon-wrapper {
    height: 32px;
    width: 32px;
    flex-shrink: 0;
    border-radius: var(--rounded-full);
    @include background-opacity(var(--ghost-white), 40%);

    display: flex;
    align-items: center;
    justify-content: center;

    .variant-icon {
      height: 24px;
      width: 24px;

      path {
        fill: var(--white);
      }
    }
  }

  .message {
    @include text-lg-medium;
    color: var(--text-primary);

    a {
      color: inherit;
    }
  }

  .close-button {
    display: flex;
    width: 20px;
    height: 20px;
    transition: background-color 0.15s ease-in-out;

    svg {
      width: inherit;
      height: inherit;

      path {
        stroke: var(--white);
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

@include mq(992) {
  .notistack-SnackbarContainer .generic-toast.align-to-app-content {
    transform: translateX(140px);
  }
}

@include mq(767, max) {
  .generic-toast {
    width: var(--medium-screen-max-content-width);
  }
}

@include mq(629, max) {
  #portal-target-node .notistack-SnackbarContainer {
    width: var(--small-screen-max-content-width);
    margin-left: 20px;
  }

  .generic-toast {
    min-height: 40px;
    width: 100%;
    padding: 4px 16px;
    margin: 0;

    .variant-icon-wrapper {
      height: 24px;
      width: 24px;

      .variant-icon {
        height: 16px;
        width: 16px;
      }
    }

    .message {
      @include text-base-medium;
    }
  }
}
