@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

@include mq(629, max) {
  .renting {
    .rent-details,
    .my-return-on-rent,
    .credit-builder,
    .rent-tracker-item,
    .rent-calendar {
      padding: 16px;
    }

    .pay-rent-with-stake {
      .content {
        padding: 16px;
      }
    }

    .rent-details {
      .content {
        width: calc(100% - 170px);
      }
    }

    .pay-rent-with-stake {
      .person-with-key-illustration {
        height: auto;
        aspect-ratio: 16 / 9;
      }

      .content {
        .title {
          @include text-lg-semibold;
        }

        .steps-title {
          @include text-base-medium;
        }
      }
    }

    .credit-builder {
      img.coin {
        height: 45px;
      }

      img.sparkle {
        height: 35px;
      }

      .title {
        @include text-lg-semibold;
      }
    }
  }
}
