@mixin poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

@mixin poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

@mixin poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

@mixin text-xxs-regular {
  @include poppins-regular;
  font-size: 10px;
  line-height: 16px;
}

@mixin text-xxs-medium {
  @include poppins-medium;
  font-size: 10px;
  line-height: 16px;
}

@mixin text-xxs-semibold {
  @include poppins-semibold;
  font-size: 10px;
  line-height: 16px;
}

@mixin text-xxs-bold {
  @include poppins-bold;
  font-size: 10px;
  line-height: 16px;
}

@mixin text-xxs-extrabold {
  @include poppins-extrabold;
  font-size: 10px;
  line-height: 16px;
}

@mixin text-xs-regular {
  @include poppins-regular;
  font-size: 12px;
  line-height: 16px;
}

@mixin text-xs-medium {
  @include poppins-medium;
  font-size: 12px;
  line-height: 16px;
}

@mixin text-xs-semibold {
  @include poppins-semibold;
  font-size: 12px;
  line-height: 16px;
}

@mixin text-xs-bold {
  @include poppins-bold;
  font-size: 12px;
  line-height: 16px;
}

@mixin text-xs-extrabold {
  @include poppins-extrabold;
  font-size: 12px;
  line-height: 16px;
}

@mixin text-sm-regular {
  @include poppins-regular;
  font-size: 13px;
  line-height: 16px;
}

@mixin text-sm-medium {
  @include poppins-medium;
  font-size: 13px;
  line-height: 16px;
}

@mixin text-sm-semibold {
  @include poppins-semibold;
  font-size: 13px;
  line-height: 16px;
}

@mixin text-sm-bold {
  @include poppins-bold;
  font-size: 13px;
  line-height: 16px;
}

@mixin text-sm-extrabold {
  @include poppins-extrabold;
  font-size: 13px;
  line-height: 16px;
}

@mixin text-base-regular {
  @include poppins-regular;
  font-size: 14px;
  line-height: 20px;
}

@mixin text-base-medium {
  @include poppins-medium;
  font-size: 14px;
  line-height: 20px;
}

@mixin text-base-semibold {
  @include poppins-semibold;
  font-size: 14px;
  line-height: 20px;
}

@mixin text-base-bold {
  @include poppins-bold;
  font-size: 14px;
  line-height: 20px;
}

@mixin text-base-extrabold {
  @include poppins-extrabold;
  font-size: 14px;
  line-height: 20px;
}

@mixin text-lg-regular {
  @include poppins-regular;
  font-size: 16px;
  line-height: 24px;
}

@mixin text-lg-medium {
  @include poppins-medium;
  font-size: 16px;
  line-height: 24px;
}

@mixin text-lg-semibold {
  @include poppins-semibold;
  font-size: 16px;
  line-height: 24px;
}

@mixin text-lg-bold {
  @include poppins-bold;
  font-size: 16px;
  line-height: 24px;
}

@mixin text-lg-extrabold {
  @include poppins-extrabold;
  font-size: 16px;
  line-height: 24px;
}

@mixin text-xl-regular {
  @include poppins-regular;
  font-size: 18px;
  line-height: 24px;
}

@mixin text-xl-medium {
  @include poppins-medium;
  font-size: 18px;
  line-height: 24px;
}

@mixin text-xl-semibold {
  @include poppins-semibold;
  font-size: 18px;
  line-height: 24px;
}

@mixin text-xl-bold {
  @include poppins-bold;
  font-size: 18px;
  line-height: 24px;
}

@mixin text-xl-extrabold {
  @include poppins-extrabold;
  font-size: 18px;
  line-height: 24px;
}

@mixin text-2xl-regular {
  @include poppins-regular;
  font-size: 20px;
  line-height: 28px;
}

@mixin text-2xl-medium {
  @include poppins-medium;
  font-size: 20px;
  line-height: 28px;
}

@mixin text-2xl-semibold {
  @include poppins-semibold;
  font-size: 20px;
  line-height: 28px;
}

@mixin text-2xl-bold {
  @include poppins-bold;
  font-size: 20px;
  line-height: 28px;
}

@mixin text-2xl-extrabold {
  @include poppins-extrabold;
  font-size: 20px;
  line-height: 28px;
}

@mixin text-3xl-regular {
  @include poppins-regular;
  font-size: 24px;
  line-height: 32px;
}

@mixin text-3xl-medium {
  @include poppins-medium;
  font-size: 24px;
  line-height: 32px;
}

@mixin text-3xl-semibold {
  @include poppins-semibold;
  font-size: 24px;
  line-height: 32px;
}

@mixin text-3xl-bold {
  @include poppins-bold;
  font-size: 24px;
  line-height: 32px;
}

@mixin text-3xl-extrabold {
  @include poppins-extrabold;
  font-size: 24px;
  line-height: 32px;
}

@mixin text-4xl-regular {
  @include poppins-regular;
  font-size: 32px;
  line-height: 40px;
}

@mixin text-4xl-medium {
  @include poppins-medium;
  font-size: 32px;
  line-height: 40px;
}

@mixin text-4xl-semibold {
  @include poppins-semibold;
  font-size: 32px;
  line-height: 40px;
}

@mixin text-4xl-bold {
  @include poppins-bold;
  font-size: 32px;
  line-height: 40px;
}

@mixin text-4xl-extrabold {
  @include poppins-extrabold;
  font-size: 32px;
  line-height: 40px;
}

@mixin text-5xl-regular {
  @include poppins-regular;
  font-size: 40px;
  line-height: 48px;
}

@mixin text-5xl-medium {
  @include poppins-medium;
  font-size: 40px;
  line-height: 48px;
}

@mixin text-5xl-semibold {
  @include poppins-semibold;
  font-size: 40px;
  line-height: 48px;
}

@mixin text-5xl-bold {
  @include poppins-bold;
  font-size: 40px;
  line-height: 48px;
}

@mixin text-5xl-extrabold {
  @include poppins-extrabold;
  font-size: 40px;
  line-height: 48px;
}

@mixin text-6xl-regular {
  @include poppins-regular;
  font-size: 48px;
  line-height: 56px;
}

@mixin text-6xl-medium {
  @include poppins-medium;
  font-size: 48px;
  line-height: 56px;
}

@mixin text-6xl-semibold {
  @include poppins-semibold;
  font-size: 48px;
  line-height: 56px;
}

@mixin text-6xl-bold {
  @include poppins-bold;
  font-size: 48px;
  line-height: 56px;
}

@mixin text-6xl-extrabold {
  @include poppins-extrabold;
  font-size: 48px;
  line-height: 56px;
}
