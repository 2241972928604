@import "../../Assets/Styles/typography-mixins.scss";

.bank-statement {
  .section-header {
    .section-title {
      @include text-3xl-semibold;
    }
  }

  .statements {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .statement-item {
      background-color: var(--white);
      border-radius: var(--rounded-lg);
      box-shadow: var(--card-drop-shadow);
      padding: 24px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 16px;

      .statement-details {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        .year,
        .month {
          @include text-xl-medium;
        }

        .year {
          color: var(--medium-grey);
        }

        .month {
          color: var(--charcoal);
        }
      }

      .elaborate-icon path {
        fill: var(--charcoal);
      }

      .MuiCircularProgress-root {
        height: 24px !important;
        width: 24px !important;
        color: currentColor;
      }
    }
  }

  .no-record {
    background-color: var(--white);
    border-radius: var(--rounded-lg);
    border: 1px solid var(--HR);
    padding: 24px;
    height: 148px;
  }
}
