@import "../../../../Assets/Styles/typography-mixins.scss";
@import "../../../../Assets/Styles/mixins.scss";

.opted-in-benefits {
  .modal-image {
    background-color: #dce5ff;
    @include flex-end;
    flex-direction: column;
    overflow: hidden;

    img {
      margin-top: 10px;
      margin-bottom: -3px;
      height: 250px;
    }
  }
}

@include mq(629, max) {
  .opted-in-benefits {
    .modal-image {
      img {
        height: auto;
        max-height: 40vh;
      }
    }
  }
}
