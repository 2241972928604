@import "../../../Assets/Styles/mixins.scss";

@include mq(629, max) {
  .trust-content {
    row-gap: 12px;

    .reviews {
      display: flex;
      flex-direction: column-reverse;

      .carousel-control-prev,
      .carousel-control-next {
        display: none;
      }
    }
  }
}

@include mq(437, max) {
  .trust-content {
    .reviews {
      .review-item {
        .review-text {
          min-height: 120px;
        }
      }
    }
  }
}
