:root {
  --white: #ffffff;
  --charcoal: #282828;
  --HR: #d8dce1;
  --robin-egg: #ebf3f7;
  --yankees-blue: #232939;
  --off-white: #f9f9f9;
  --medium-grey: #868686;
  --stake-blue: #149ce0;
  --smoke: #efefef;
  --success-green: #15cb12;
  --stake-yellow: #ffc845;
  --error-red: #f14242;
  --error-red-hovered: #e41111;
  --ghost-white: #f9f9f9;
  --amazon: #326857;
  --alice-blue: #eff6ff;
  --new-car: #1d4ed8;
  --bright-gray: #eaecef;
  --lotion: #fafafa;

  --bg-primary: #212737;
  --bg-secondary: #171b26;

  --text-primary: #f9f9f9;
  --text-secondary: #183153;

  --loader-bg-light-primary: #d1d5db;
  --loader-bg-light-secondary: #e5e7eb;
  --loader-bg-dark-primary: #4b5563;
  --loader-bg-dark-secondary: #374151;
  --loader-bg-dark-tertiary: #2d3543;

  --primary-0: #f6fffa;
  --primary-50: #eefff5;
  --primary-100: #dbffea;
  --primary-200: #bbffd6;
  --primary-300: #a1ffc7;
  --primary-400: #54ff99;
  --primary-500: #3db56d;
  --primary-600: #2d8350;
  --primary-700: #266a42;
  --primary-800: #163924;
  --primary-900: #0c1811;

  --secondary-0: #f3fafd;
  --secondary-50: #e7f5fc;
  --secondary-100: #ceeaf8;
  --secondary-200: #a1d7f3;
  --secondary-300: #7ec9ee;
  --secondary-400: #149ce0;
  --secondary-500: #106f9f;
  --secondary-600: #0d5173;
  --secondary-700: #0c435e;
  --secondary-800: #0a2532;
  --secondary-900: #081016;

  --rounded-sm: 4px;
  --rounded-md: 6px;
  --rounded-lg: 8px;
  --rounded-xl: 10px;
  --rounded-2xl: 12px;
  --rounded-3xl: 14px;
  --rounded-4xl: 16px;
  --rounded-full: 9999px;

  --card-drop-shadow: 0px 0px 1px 0px rgba(24, 26, 32, 0.1), 0px 3px 6px 0px rgba(71, 77, 87, 0.04),
    0px 1px 2px 0px rgba(24, 26, 32, 0.04);
  --modal-drop-shadow: 0px 3px 6px 0px rgba(71, 77, 87, 0.04), 0px 1px 6px 0px rgba(24, 26, 32, 0.1);

  --large-screen-max-content-width: 600px;
  --medium-screen-max-content-width: 584px;
  --small-screen-max-content-width: calc(100% - 32px);
}
