@import "./typography-mixins.scss";

.text-xxs-regular {
  @include text-xxs-regular;
}

.text-xxs-medium {
  @include text-xxs-medium;
}

.text-xxs-semibold {
  @include text-xxs-semibold;
}

.text-xxs-bold {
  @include text-xxs-bold;
}

.text-xxs-extrabold {
  @include text-xxs-extrabold;
}

.text-xs-regular {
  @include text-xs-regular;
}

.text-xs-medium {
  @include text-xs-medium;
}

.text-xs-semibold {
  @include text-xs-semibold;
}

.text-xs-bold {
  @include text-xs-bold;
}

.text-xs-extrabold {
  @include text-xs-extrabold;
}

.text-sm-regular {
  @include text-sm-regular;
}

.text-sm-medium {
  @include text-sm-medium;
}

.text-sm-semibold {
  @include text-sm-semibold;
}

.text-sm-bold {
  @include text-sm-bold;
}

.text-sm-extrabold {
  @include text-sm-extrabold;
}

.text-base-regular {
  @include text-base-regular;
}

.text-base-medium {
  @include text-base-medium;
}

.text-base-semibold {
  @include text-base-semibold;
}

.text-base-bold {
  @include text-base-bold;
}

.text-base-extrabold {
  @include text-base-extrabold;
}

.text-lg-regular {
  @include text-lg-regular;
}

.text-lg-medium {
  @include text-lg-medium;
}

.text-lg-semibold {
  @include text-lg-semibold;
}

.text-lg-bold {
  @include text-lg-bold;
}

.text-lg-extrabold {
  @include text-lg-extrabold;
}

.text-xl-regular {
  @include text-xl-regular;
}

.text-xl-medium {
  @include text-xl-medium;
}

.text-xl-semibold {
  @include text-xl-semibold;
}

.text-xl-bold {
  @include text-xl-bold;
}

.text-xl-extrabold {
  @include text-xl-extrabold;
}

.text-2xl-regular {
  @include text-2xl-regular;
}

.text-2xl-medium {
  @include text-2xl-medium;
}

.text-2xl-semibold {
  @include text-2xl-semibold;
}

.text-2xl-bold {
  @include text-2xl-bold;
}

.text-2xl-extrabold {
  @include text-2xl-extrabold;
}

.text-3xl-regular {
  @include text-3xl-regular;
}

.text-3xl-medium {
  @include text-3xl-medium;
}

.text-3xl-semibold {
  @include text-3xl-semibold;
}

.text-3xl-bold {
  @include text-3xl-bold;
}

.text-3xl-extrabold {
  @include text-3xl-extrabold;
}

.text-4xl-regular {
  @include text-4xl-regular;
}

.text-4xl-medium {
  @include text-4xl-medium;
}

.text-4xl-semibold {
  @include text-4xl-semibold;
}

.text-4xl-bold {
  @include text-4xl-bold;
}

.text-4xl-extrabold {
  @include text-4xl-extrabold;
}

.text-5xl-regular {
  @include text-5xl-regular;
}

.text-5xl-medium {
  @include text-5xl-medium;
}

.text-5xl-semibold {
  @include text-5xl-semibold;
}

.text-5xl-bold {
  @include text-5xl-bold;
}

.text-5xl-extrabold {
  @include text-5xl-extrabold;
}

.text-6xl-regular {
  @include text-6xl-regular;
}

.text-6xl-medium {
  @include text-6xl-medium;
}

.text-6xl-semibold {
  @include text-6xl-semibold;
}

.text-6xl-bold {
  @include text-6xl-bold;
}

.text-6xl-extrabold {
  @include text-6xl-extrabold;
}
