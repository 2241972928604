@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

@include mq(629, max) {
  .connect-your-bank {
    .earn-more-card {
      min-height: 146px;
      padding: 16px;

      .card-content {
        .texts {
          .title {
            @include text-base-medium;
            white-space: initial;
          }
        }

        .button {
          width: 120px;
          @include text-sm-medium;
        }
      }
    }
  }

  .bank-account-item {
    .brand-container,
    .account-details-container {
      padding: 16px;
    }

    .brand-container {
      .bank-brand-logo {
        height: 24px;
        width: auto;
      }

      .bank-brand-logo-placeholder {
        @include text-xl-semibold;
        color: var(--charcoal);
      }
    }

    .account-details-container {
      .account-name {
        @include text-lg-medium;
      }

      .account-number {
        @include text-xs-medium;
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: var(--HR);
    }

    button.delete svg,
    .active-icon {
      height: 24px;
    }

    + .bank-account-item {
      margin-top: 8px;
    }
  }
}
