@import "./mixins.scss";

.MuiRating-root {
  &.MuiRating-sizeSmall {
    font-size: 16px;
    line-height: 16px;
  }

  &.MuiRating-sizeMedium {
    font-size: 24px;
    line-height: 24px;
  }

  .MuiRating-icon {
    &.MuiRating-iconEmpty {
      color: var(--white);
    }

    &.MuiRating-iconFilled {
      color: var(--primary-400);
    }
  }
}

.step-indicator {
  @include flex-center;
  gap: 16px;

  .step {
    flex: 1 0 auto;
    height: 4px;
    background: var(--HR);
    border-radius: var(--rounded-full);

    &.active {
      background: var(--stake-blue);
    }
  }
}

.custom-stepper {
  .MuiStep-root {
    .MuiStepLabel-root {
      .MuiStepLabel-iconContainer .step-icon {
        height: 16px;
        width: 16px;
        background-color: var(--HR);
        border-radius: var(--rounded-full);
      }

      .MuiStepLabel-labelContainer .MuiStepLabel-label {
        @include text-lg-regular;
        color: var(--charcoal);
      }

      &.MuiStepLabel-root {
        padding: 0;
      }
    }

    .MuiStepConnector-root {
      &.MuiStepConnector-horizontal {
        top: 7px;
        left: calc(-50% + 8px);
        right: calc(50% + 8px);

        .MuiStepConnector-line {
          border-top: 2px solid var(--HR);
        }
      }
    }

    &.Mui-completed {
      .MuiStepLabel-root {
        .MuiStepLabel-iconContainer .step-icon {
          background-color: var(--stake-blue);
        }

        .MuiStepLabel-labelContainer .MuiStepLabel-label {
          color: var(--stake-blue);
        }
      }

      .MuiStepConnector-root {
        &.MuiStepConnector-horizontal {
          .MuiStepConnector-line {
            border-top-color: var(--stake-blue);
          }
        }
      }
    }

    &.active {
      .MuiStepLabel-root {
        .MuiStepLabel-labelContainer .MuiStepLabel-label {
          @include text-lg-semibold;
        }
      }
    }

    &.MuiStep-vertical {
      position: relative;

      &:not(:first-child) .MuiStepLabel-root::before,
      &:not(:last-child) .MuiStepLabel-root::after {
        content: "";
        position: absolute;
        left: 7px;
        height: calc(50% - 8px);
        width: 2px;
        background-color: var(--HR);
      }

      &:not(:first-child) .MuiStepLabel-root::before {
        top: 0;
      }

      &:not(:last-child) .MuiStepLabel-root::after {
        bottom: 0;
      }

      &.Mui-completed {
        &:not(:first-child) .MuiStepLabel-root::before {
          background-color: var(--stake-blue);
        }

        &:not(.active):not(:last-child) .MuiStepLabel-root::after {
          background-color: var(--stake-blue);
        }
      }
    }
  }

  .MuiStepConnector-root {
    &.MuiStepConnector-vertical {
      margin: 0 0 0 7px;

      .MuiStepConnector-line {
        border-left: 2px solid var(--HR);
      }
    }

    &.MuiStepConnector-vertical.Mui-completed {
      .MuiStepConnector-line {
        border-left-color: var(--stake-blue);
      }
    }
  }
}
