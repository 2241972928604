@import "bootstrap/scss/mixins/banner";
@include bsBanner("");

// scss-docs-start import-stack
// Configuration
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";

$spacer: 1rem;
$spacers: (
  0: 0,
  //0px
  1: $spacer * 0.25,
  //4px
  2: $spacer * 0.5,
  //8px
  3: $spacer * 0.75,
  //12px
  4: $spacer,
  //16px
  5: $spacer * 1.25,
  //20px
  6: $spacer * 1.5,
  //24px
  7: $spacer * 1.75,
  //28px
  8: $spacer * 2,
  //32px
  9: $spacer * 2.25,
  //36px
  10: $spacer * 2.5,
  //40px
  11: $spacer * 2.75,
  //44px
  12: $spacer * 3,
  //48px
);

@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$grid-breakpoints: (
  xs: 0,
  sm: 630px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

$utilities: map-merge(
  $utilities,
  (
    "gap": (
      responsive: true,
      property: gap,
      class: gap,
      values: $spacers,
    ),
    "row-gap": (
      responsive: true,
      property: row-gap,
      class: row-gap,
      values: $spacers,
    ),
    "column-gap": (
      responsive: true,
      property: column-gap,
      class: column-gap,
      values: $spacers,
    ),
    "white-space": (
      responsive: false,
      property: white-space,
      class: white-space,
      values: (
        pre-line: pre-line,
        nowrap: nowrap,
      ),
    ),
  )
);

// Utilities
@import "bootstrap/scss/utilities/api";
// scss-docs-end import-stack
