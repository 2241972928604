@import "../../Assets/Styles/mixins.scss";
@import "../../Assets/Styles/typography-mixins.scss";

@include mq(767, max) {
  .banking {
    .banking-details {
      .banking-action-buttons {
        margin: 16px 0 20px 0;

        .MuiDivider-root {
          border-right-width: 16px;
        }
      }
    }
  }
}

@include mq(629, max) {
  .banking {
    .banking-details {
      .banking-account-info {
        .balance-container {
          padding: 20px 24px;

          .balance-label,
          .available-balance {
            @include text-base-regular;
          }
        }

        .account-info {
          display: block;
          padding: 16px;

          .account-number {
            margin-bottom: 12px;
          }

          .account-number,
          .routing-number {
            padding: 0;

            .label,
            .value {
              @include text-base-medium;
            }

            .value {
              .icon {
                margin-left: 6px;
              }
            }
          }

          .MuiDivider-root {
            display: none;
          }
        }
      }

      .banking-action-buttons {
        .add-cash-button,
        .cash-out-button {
          height: 48px;
          @include text-lg-medium;
          line-height: 16px;
        }
      }

      .banking-activity {
        .section-header {
          .section-title,
          .see-all-button {
            @include text-base-medium;
          }
        }

        .activity-list {
          padding: 16px;

          .activity-item {
            .activity-name,
            .activity-amount,
            .activity-date,
            .balance-after-activity {
              @include text-lg-medium;
            }
          }

          .activity-divider {
            margin: 8px 0;
          }

          &.no-records {
            height: 120px;
          }
        }
      }
    }

    .earn-more-section,
    .banking-services-section {
      .earn-more-cards,
      .banking-service-buttons {
        margin: 0;
        row-gap: 16px;
      }
    }
  }
}
