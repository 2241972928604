@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

@include mq(629, max) {
  .add-cash,
  .cash-out {
    .content {
      gap: 40px;
    }
  }

  .add-cash,
  .cash-out,
  .recurring-deposit.content {
    .top-container {
      .daily-limit-label {
        @include text-base-medium;
      }

      .max-no-of-transfers-badge {
        background-color: var(--secondary-100);
      }
    }

    .bottom-container {
      .transfer-types {
        gap: 16px;

        .transfer-type {
          padding: 16px;

          .icon-wrapper {
            height: 26px;
            width: 26px;

            .icon {
              height: 14px;
              width: 14px;
            }
          }

          .title {
            @include text-base-regular;
            margin: 5px 0 2px 0;
          }

          .description {
            @include text-xs-regular;
          }

          .restricted-badge {
            padding: 2px 6px;
            @include text-xxs-medium;
          }
        }
      }

      .details {
        padding: 4px 0;

        .detail-row {
          .detail-label {
            @include text-base-medium;
          }

          .detail-value {
            @include text-base-medium;
            gap: 12px;

            .edit {
              svg {
                height: 20px;
                width: 20px;
              }
            }
          }

          .info {
            @include text-sm-regular;
          }
        }
      }
    }
  }
}
