.custom-menu {
  .dropdown-toggle {
    &.no-style {
      &::after {
        display: none;
      }
    }

    &.render-as-field,
    &.border-less {
      color: var(--charcoal);

      @include flex-start;
      position: relative;

      &::after {
        position: absolute;
        width: 10px;
        height: 10px;
        margin: 0;
        flex-shrink: 0;
        border: none;
        background: url("../Icons/chevron-right.svg") no-repeat 0 0;
        background-size: 10px 10px;
        transform: rotateZ(90deg);
      }
    }

    &.render-as-field {
      width: 200px;
      border: 0.8px solid var(--HR);
      padding: 10px 34px 10px 12px;
      border-radius: var(--rounded-sm);
      @include text-xs-medium;

      &::after {
        top: 13px;
        right: 12px;
      }
    }

    &.border-less {
      width: auto;
      border: none;
      padding: 0;
      padding-right: 16px;
      @include text-base-medium;

      &::after {
        top: 5px;
        right: 0;
      }
    }
  }

  .dropdown-menu {
    width: 200px;
    max-height: 168px;
    padding: 4px 0;
    border-radius: var(--rounded-sm);
    background: var(--white);
    box-shadow: var(--modal-drop-shadow);
    border: none;
    overflow: auto;

    .dropdown-item {
      @include text-xs-medium;
      color: var(--medium-grey);
      @include flex-space-between;
      padding: 8px 12px;
      gap: 10px;

      svg.tick {
        width: 12px;
        height: 12px;

        path {
          fill: var(--charcoal);
        }
      }

      &:hover,
      &:focus {
        background: var(--lotion);
      }

      &.selected {
        color: var(--charcoal);
      }
    }
  }
}
