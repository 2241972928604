@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

.rent-tracker-item {
  .rent-tracker-header,
  .rent-tracker-row {
    display: grid;
    grid-template-columns: auto 84px 84px;
    gap: 24px;
    align-items: center;
  }

  .rent-tracker-header {
    .title {
      @include text-lg-medium;
      color: var(--medium-grey);
    }
  }

  .rent-tracker-row {
    height: 72px;
    padding: 8px 0;

    + .rent-tracker-row {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: -0.5px;
        left: 0;
        right: 0;
        height: 1px;
        background: var(--HR);
      }
    }

    &:last-child {
      height: 64px;
      padding: 8px 0 0 0;
    }

    .text-content {
      @include text-xl-medium;
      color: var(--charcoal);
    }

    .status-icon {
      margin: 0 auto;
    }
  }

  &.no-records {
    @include text-base-medium;
    height: 148px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

@include mq(629, max) {
  .rent-tracker-item {
    + .rent-tracker {
      margin-top: 16px;
    }

    .rent-tracker-header,
    .rent-tracker-row {
      grid-template-columns: auto 68px 68px;
    }

    .rent-tracker-header {
      .title {
        @include text-base-medium;
      }
    }

    .rent-tracker-row {
      height: 56px;

      &:last-child {
        height: 48px;
      }

      .text-content {
        @include text-lg-medium;
      }
    }

    &.no-records {
      height: 120px;
    }
  }
}
