@import "../../Assets/Styles/typography-mixins.scss";

.banking {
  .banking-details {
    background-color: var(--bg-primary);

    .MuiDivider-root {
      border-right-width: 1.4px;
      border-color: var(--HR);
    }

    .banking-account-info {
      background-color: var(--bg-secondary);
      border-radius: var(--rounded-lg);

      .balance-container {
        padding: 32px 24px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .balance-label,
        .available-balance {
          @include text-xl-regular;
          color: var(--medium-grey);
          margin-bottom: 0;

          display: flex;
          align-items: center;

          .icon {
            margin-left: 4px;
          }
        }
      }

      .account-info {
        display: flex;
        align-items: center;

        .account-number,
        .routing-number {
          flex: 1 0;

          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 24px;

          .label,
          .value {
            @include text-lg-medium;
            color: var(--text-primary);
          }

          .value {
            display: flex;
            align-items: center;

            .icon {
              margin-left: 8px;
            }
          }
        }
      }
    }

    .banking-action-buttons {
      display: flex;
      margin-top: 24px;

      .MuiDivider-root {
        border-right-width: 24px;
        border-color: transparent;
      }

      .add-cash-button,
      .cash-out-button {
        flex-grow: 1;
        height: 56px;
        padding: 16px 24px;
        border-radius: var(--rounded-lg);
        background-color: var(--bg-secondary);
        @include text-xl-medium;
        color: var(--text-primary);
      }
    }

    .banking-activity {
      margin-top: 20px;

      .section-header {
        margin-bottom: 12px;

        .section-title,
        .see-all-button {
          @include text-xl-medium;
          color: var(--text-primary);
        }

        .see-all-button {
          text-decoration: underline;
        }
      }

      .activity-list {
        padding: 24px;
        border-radius: var(--rounded-lg);
        background-color: var(--bg-secondary);

        .activity-item {
          .row-1 {
            margin-bottom: 8px;
          }

          .activity-name,
          .activity-amount,
          .activity-date,
          .balance-after-activity {
            @include text-xl-medium;
            color: var(--text-primary);
          }

          .activity-date,
          .balance-after-activity {
            opacity: 0.6;
          }
        }

        .activity-divider {
          margin: 16px 0;
        }

        &.no-records {
          @include text-xl-medium;
          color: var(--text-primary);
          height: 148px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .earn-more-section,
  .banking-services-section {
    padding: 24px 0;

    .earn-more-cards,
    .banking-service-buttons {
      row-gap: 24px;
    }
  }
}
