@import "../../../Assets/Styles/typography-mixins.scss";
@import "../../../Assets/Styles/mixins.scss";

.funding-instrument-options {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .funding-instrument-option {
    position: relative;
    flex: 1 0;
    @include flex-space-between;
    gap: 16px;
    padding: 24px;
    border-radius: var(--rounded-md);
    border: 1px solid var(--HR);

    .funding-instrument {
      @include flex-center;
      gap: 16px;

      .funding-instrument-icon {
        height: 40px;
        width: 40px;
        flex-shrink: 0;

        path {
          fill: var(--medium-grey);
        }
      }

      .funding-instrument-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        .funding-instrument-title {
          @include text-base-medium;
          color: var(--charcoal);
          text-align: left;
          margin-bottom: 0;
        }

        .funding-instrument-description {
          @include text-xs-regular;
          color: var(--medium-grey);
          text-align: left;
          margin-bottom: 0;
        }
      }
    }

    &.selected {
      border-color: var(--stake-blue);

      .funding-instrument-icon {
        path {
          fill: var(--stake-blue);
        }
      }
    }

    .custom-radio-button {
      flex-shrink: 0;
    }

    .restricted-badge {
      position: absolute;
      top: 0;
      right: 0;
      padding: 4px 12px;
      border-radius: 0px var(--rounded-sm);
      @include background-opacity(var(--medium-grey), 10%);
      @include text-xs-medium;
      color: var(--medium-grey);
    }
  }
}
