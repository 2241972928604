@import "../../Assets/Styles/mixins.scss";

.renting {
    &.skeleton {
        .rent-details {
            .content {
                gap: 56px;
            }
        }
    }
}

@include mq(629, max) {
    .renting {
        &.skeleton {
            .credit-builder {
                row-gap: 32px;

                .button {
                    height: 40px !important;
                }
            }

            .rent-details {
                .content {
                    gap: 40px;
                }

                .image {
                    width: 128px !important;
                    height: 128px !important;
                }
            }
        }
    }
}