@import "../../../Assets/Styles/animations.scss";
.onboarding-page.splash {
  header {
    animation: fade-in 0.35s ease-in-out 0.45s both;
  }
}

.splash-content {
  display: flex;
  flex-direction: column;
  padding-left: clamp(0px, (100vw - 536px) * (48/94), 48px);
  padding-right: clamp(0px, (100vw - 536px) * (48/94), 48px);
  position: relative;
  font-family: "Anton", sans-serif;
  text-transform: uppercase;

  .vector {
    position: absolute;
    top: calc(50% + 15px);
    width: clamp(196px, (100vw - 375px) * (94 / 155) + 196px, 290px);
    transform: translateX(3px);
  }

  .animate-in-order {
    animation: slide-bottom-to-top-fade-in 0.35s ease-in-out 0.45s both;

    &.order-1 {
      animation-delay: 0.45s;
    }

    &.order-2 {
      animation-delay: 1.25s;
    }

    &.order-3 {
      animation-delay: 2.05s;
    }

    &.order-4 {
      animation-delay: 2.85s;

      &.vector {
        animation-name: fade-in;
      }
    }
  }

  .stake-is,
  .much-more {
    color: var(--text-primary);
    font-family: Anton;
    font-size: 32px;
    font-size: clamp(28px, (100vw - 375px) * (50 / 155) + 28px, 32px);
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.48px;
  }

  .stake-is {
    margin-bottom: 6px;
    transform: translateX(6px);
  }

  .much-more {
    text-align: right;
    margin-top: 6px;
    transform: translateX(-6px);
  }

  .cash,
  .back,
  .on,
  .rent {
    color: var(--primary-400);
    font-family: Anton;
    font-size: clamp(130px, (100vw - 375px) * (50 / 155) + 130px, 180px);
    font-style: normal;
    font-weight: 400;
    line-height: 92%;
    letter-spacing: 3.15px;
  }

  .on,
  .rent {
    text-align: right;
    color: var(--text-primary);
  }
}

.splash-cta-button {
  animation: slide-bottom-to-top-fade-in 0.35s ease-in-out 3.65s both;
}

.logo-animation {
  height: 94px;
}
