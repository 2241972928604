/* mobile-first media query */
@mixin mq($value, $function: min) {
  @if (type-of($value) != number) {
    @error "Sorry, but `#{$value}` is not a valid argument for the mq() mixin.";
  } @else if (unitless($value)) {
    $value: $value + "px";
  }

  @if ($function == min) {
    @media only screen and (min-width: $value) {
      @content;
    }
  } @else {
    @media only screen and (max-width: $value) {
      @content;
    }
  }
}

@function color-opacity($color, $opacity: 30%) {
  @return rgb(from $color r g b / $opacity);
}

@mixin background-opacity($color, $opacity: 30%) {
  background: $color; /* The Fallback */
  background: color-opacity($color, $opacity);
}

@mixin flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin styled-card {
  background: var(--white);
  border-radius: var(--rounded-lg);
  box-shadow: var(--card-drop-shadow);
}
