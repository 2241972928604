@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

.stake-pay-3 {
  .rent-payment-item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 8px;

    .info {
      width: 100%;

      .item-detail {
        @include flex-space-between;

        .item-label {
          @include flex-center;
          gap: 4px;

          .know-more {
            display: flex;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }

        &.highlight .item-label,
        &.highlight .item-value {
          font-weight: 500 !important;
        }
      }

      & > .item-detail .item-label,
      & > .item-detail .item-value {
        @include text-base-medium;
        color: var(--charcoal);
      }

      .item-detail .item-value {
        &.has-edit-icon {
          @include flex-center;
          gap: 8px;

          svg {
            width: 14px;
            height: 14px;
          }
        }

        &.pad-to-align {
          padding-right: 22px;
        }
      }

      .payment-item-list {
        .item-detail-group + .item-detail-group {
          margin-top: 16px;
        }

        .item-detail + .item-detail {
          margin-top: 12px;
        }

        .item-detail .item-label,
        .item-detail .item-value {
          @include text-sm-regular;
          color: var(--charcoal);
        }
      }
    }

    & + .rent-payment-item {
      margin-top: 20px;
    }
  }

  .payment-card {
    background: var(--white);
    border-radius: var(--rounded-md);
    border: 1px solid var(--HR);
    padding: 16px;

    .card-row {
      @include flex-space-between;
      gap: 16px;

      & + .MuiDivider-root {
        border: 0.8px solid var(--HR);
        margin: 16px 0;
        opacity: 1;
      }
    }

    .rent-payment-item:last-child .payment-item-list {
      padding-bottom: 0;
      border-bottom: 0;
    }

    .no-rent-item-found {
      @include text-base-medium;
      color: var(--charcoal);
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .payment-card {
    .card-row {
      .payment-method {
        display: flex;
        gap: 12px;

        .payment-method-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          border-radius: var(--rounded-full);
          flex-shrink: 0;
          background: var(--medium-grey);

          svg {
            width: 20px;
            height: 20px;
          }

          &.stake-checking {
            background: var(--success-green);
          }

          &:not(.stake-checking) {
            svg path {
              fill: var(--white);
            }
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .title {
            display: flex;
            gap: 6px;
            @include text-sm-medium;
            color: var(--charcoal);
            margin: 0;
            padding-bottom: 2px;

            .select-payment-entity {
              @include flex-center;
              gap: 4px;
              @include text-xxs-medium;
              color: var(--stake-blue);

              svg {
                width: 12px;
                height: 12px;

                path {
                  fill: var(--stake-blue);
                }
              }
            }
          }

          .cashback-summary {
            @include text-xxs-regular;
            color: var(--success-green);
          }

          .description {
            @include text-xs-regular;
            color: var(--medium-grey);
            padding-top: 2px;
          }
        }
      }
    }
  }

  &.select .card-section-wrapper {
    .card-section {
      padding: 0;
      background: var(--white);

      .rent-details {
        position: relative;
        padding: 32px 24px;
        border-bottom: 1px solid var(--HR);

        .detail {
          display: flex;
          flex-direction: column;
          gap: 4px;
          max-width: calc(100% - 148px);

          .detail-name {
            @include text-base-regular;
            color: var(--medium-grey);
          }

          .detail-value {
            @include text-lg-medium;
            color: var(--charcoal);
            white-space: pre-line;
          }
        }

        .illustration {
          height: 100%;
          max-height: 112px;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }

      .content {
        padding: 24px;
      }
    }
  }

  &.manage {
    .status-badge {
      @include flex-center;
      padding: 4px 8px;
      gap: 4px;
      border-radius: var(--rounded-full);
      border: 1px solid;
      background: var(--white);
      @include text-xxs-medium;
      color: var(--charcoal);
      width: fit-content;

      .badge-icon {
        width: 12px;
        height: 12px;
      }

      &.scheduled,
      &.initiated {
        border-color: var(--success-green);
      }

      &.ongoing {
        border-color: var(--stake-blue);
      }

      &.cancelled {
        border-color: var(--error-red);
      }

      &.not-scheduled {
        border-color: var(--medium-grey);
      }
    }
  }

  &.rent-payments {
    .card-row {
      > svg {
        width: 20px;
        height: 20px;
      }

      & + .card-row {
        margin-top: 12px;
      }
    }

    .payment-item-list {
      border-bottom: none;
      padding: 8px 0 0 0;

      .card-row + .card-row {
        margin-top: 8px;
      }
    }

    .status-badge {
      @include flex-center;
      padding: 4px 6px;
      gap: 4px;
      border-radius: var(--rounded-sm);
      @include text-xxs-medium;
      color: var(--white);
      width: fit-content;

      &.success {
        background-color: var(--success-green);
      }

      &.failed {
        background-color: var(--error-red);
      }
    }
  }

  .content.error {
    justify-content: center;
    gap: 0;

    > * {
      max-width: 400px;
    }

    .error-title {
      @include text-xl-semibold;
      color: var(--charcoal);
      text-align: center;
      margin: 24px 0 4px;
    }

    .error-description {
      @include text-base-regular;
      color: var(--medium-grey);
      text-align: center;
      margin-bottom: 24px;
    }

    .primary-button {
      width: 280px;
    }
  }
}
