@import "./typography-mixins.scss";
@import "./mixins.scss";

.opaque-70 {
  opacity: 0.7;
}

.opaque-60 {
  opacity: 0.6;
}

.opaque-50 {
  opacity: 0.5;
}

.onboarding-page,
.error-page {
  min-height: 100vh;
  min-height: 100dvh;
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  .status-container {
    @include flex-center;
    flex-direction: column;

    .status-icon {
      height: 72px;
      width: 72px;
    }
  }

  .error-container {
    max-width: 424px;
  }

  .error-code-text {
    color: var(--primary-400);
    text-align: center;
    font-family: Anton;
    font-size: 128px;
    line-height: 128px;
    letter-spacing: 16px;
  }

  .error-title {
    @include text-3xl-semibold;
    text-align: center;
    color: var(--text-primary);
    text-transform: uppercase;
  }

  .error-description {
    @include text-lg-regular;
    text-align: center;
    color: var(--text-primary);
  }

  .action-container.split-equally {
    flex-wrap: wrap;

    > * {
      min-width: 200px;
    }
  }

  .dummy-footer {
    height: 96px;
  }
}

.authenticated-page {
  min-height: 100vh;
  min-height: 100dvh;
  background-color: var(--bg--robin-egg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

header {
  width: 100%;
  padding: 32px 36px;
}

.logo {
  height: 32px;

  &.dark path,
  &.dark-with-accentuated-horn path {
    fill: var(--bg-primary);
  }

  &.dark-with-accentuated-horn path.symbol-horn {
    fill: var(--primary-400);
  }
}

header.light {
  .logo path {
    fill: var(--bg-primary);
  }

  .logo path.symbol-horn {
    fill: var(--primary-400);
  }
}

.max-width-container {
  margin: 0 auto;
  width: var(--large-screen-max-content-width);
}

.app-utility-container {
  min-height: 100vh;
  min-height: 100dvh;
  background-color: var(--robin-egg);

  display: flex;
  position: relative;

  header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 28px 24px 8px 24px;
    display: none;

    &.dark {
      background-color: var(--bg-primary);
    }
  }

  .app-utility-sidebar {
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--white);
    width: 280px;
    height: 100vh;
    height: 100dvh;
    padding: 48px 32px;

    display: flex;
    flex-direction: column;

    .logo {
      margin: 0 24px 48px;
      flex: none;
    }

    .sidebar-nav {
      width: 100%;
      flex: 1 0;

      display: flex;
      flex-direction: column;

      .spacer {
        flex: 1 0;
      }

      .sidebar-nav-item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        padding: 12px 24px;
        border-radius: var(--rounded-md);
        @include text-lg-medium;
        color: var(--text-secondary);
        text-decoration: none;
        opacity: 0.3;

        .nav-icon {
          margin-right: 16px;
        }

        &.active {
          opacity: 1;
          background-color: var(--primary-400);
        }
      }
    }
  }

  .app-utility-navbar {
    position: sticky;
    left: 0;
    bottom: 0;
    right: 0;
    height: 72px;
    padding: 12px 8px;

    display: none;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    background-color: var(--white);

    .navbar-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 127px;

      @include text-xs-medium;
      text-decoration: none;
      color: var(--text-secondary);

      opacity: 0.3;

      .nav-icon {
        margin-bottom: 6px;
      }

      &.active {
        opacity: 1;
        position: relative;

        &::after {
          position: absolute;
          content: "";
          top: -13px;
          left: 0;
          width: 100%;
          height: 2.75px;
          border-radius: 0px 0px var(--rounded-xl) var(--rounded-xl);
          background: var(--text-secondary);
        }
      }
    }
  }

  .app-content {
    flex: 1 0;
  }
}

section {
  padding: 48px 0;
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  &.content-flex-start {
    justify-content: flex-start;
    column-gap: 16px;
  }

  .section-title,
  .see-all-button {
    margin: 0;
  }

  .section-title {
    @include text-xl-semibold;
    color: var(--charcoal);
  }

  .see-all-button {
    @include text-base-medium;
    color: var(--stake-blue);
  }

  .go-back-button,
  .add-button,
  .close-button {
    height: 24px;
    width: 24px;
    flex-shrink: 0;

    svg {
      height: inherit;
      width: inherit;

      path {
        fill: var(--charcoal);
      }
    }
  }

  + .content,
  + .loading-content {
    min-height: calc(100vh - 152px);
    min-height: calc(100dvh - 152px);
  }
}

.section-header + .loading-content,
.loading-content.card-section-loader {
  @include flex-center;

  .MuiCircularProgress-root {
    height: 24px !important;
    width: 24px !important;
    color: currentColor;
  }
}

.card-section-wrapper {
  .card-section {
    border-radius: var(--rounded-lg);
    background: var(--white);
    box-shadow: var(--card-drop-shadow);
    padding: 24px;
    min-height: calc(100vh - 96px);
    min-height: calc(100dvh - 96px);

    display: flex;
    flex-direction: column;

    .section-header {
      .MuiCircularProgress-root {
        height: 24px !important;
        width: 24px !important;
        color: var(--charcoal);
      }

      .dummy {
        width: 24px;
      }
    }

    .content,
    .loading-content {
      flex-grow: 1;
      min-height: auto;
    }

    .content {
      @include flex-space-between;
      flex-direction: column;
      gap: 24px;

      & > * {
        width: 100%;
      }
    }
  }
}

.account-detail-card {
  border: 1px solid var(--HR);
  border-radius: var(--rounded-md);
  padding: 20px 24px;

  @include flex-space-between;
  gap: 24px;

  .account-detail-label {
    @include text-xs-regular;
    color: var(--medium-grey);
    margin-bottom: 4px;
  }

  .account-detail-value {
    @include text-base-medium;
    color: var(--charcoal);
  }

  .account-detail + .account-detail {
    .account-detail-label,
    .account-detail-value {
      text-align: right;
    }
  }
}

.copy-button {
  padding: 8px 12px;
  @include flex-center;
  gap: 6px;
  @include text-xs-medium;
  color: var(--charcoal);
  border-radius: var(--rounded-md);
  border: 1px solid var(--HR);

  .clipboard-icon {
    flex-shrink: 0;
  }
}

.dummy {
  visibility: hidden;
}

ul.benefits-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;

  li {
    background: url("../../Assets/Icons/tick.svg") no-repeat 0 0;
    background-size: 20px 20px;
    padding-left: 28px;
    text-align: left;
    @include text-sm-regular;
    line-height: 20px;
    color: var(--charcoal);
    white-space: pre-line;

    &.alert-list-style {
      background: url("../../Assets/Icons/warning-triangle.svg") no-repeat 0 0;
    }

    + li {
      margin-top: 16px;
    }
  }
}

.numbered-list {
  .list-item {
    display: flex;
    gap: 10px;

    + .list-item {
      margin-top: 16px;
    }

    .list-item-badge {
      font-size: 20px;
      line-height: 20px;
    }

    .list-item-content {
      .title {
        @include text-sm-medium;
        color: var(--charcoal);
        margin-bottom: 6px;
      }

      .description {
        @include text-sm-regular;
        color: var(--medium-grey);
        margin: 0;
      }
    }
  }
}

.collapse-button {
  @include flex-space-between;
  gap: 6px;

  & .chevron {
    margin: 2px;
    width: 12px;
    height: 12px;
    transform: rotate(90deg);

    path {
      fill: var(--charcoal);
    }
  }

  &.open .chevron {
    transform: rotate(270deg);
  }
}

.info-alert {
  display: flex;
  padding: 8px 12px;
  gap: 8px;

  @include text-xs-regular;
  line-height: 20px;
  color: var(--stake-blue);
  border-radius: var(--rounded-sm);
  @include background-opacity(var(--stake-blue), 6%);
  border: 1px solid color-opacity(var(--stake-blue), 60%);

  svg path {
    fill: var(--stake-blue);
  }

  &.error {
    color: var(--error-red);
    @include background-opacity(var(--error-red), 6%);
    border-color: color-opacity(var(--error-red), 60%);

    svg path {
      fill: var(--error-red);
    }
  }
}

$borderRadius: --rounded-sm, --rounded-md, --rounded-lg, --rounded-xl, --rounded-2xl, --rounded-full;

@each $current-border-radius in $borderRadius {
  .border#{$current-border-radius} {
    border-radius: var($current-border-radius) !important;
  }
}

:root {
  scrollbar-color: color-opacity(var(--charcoal), 50%) var(--white);
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: var(--white);
}

::-webkit-scrollbar-thumb {
  border-radius: 3.5px;
  @include background-opacity(var(--charcoal), 50%);
}

#astra-iframe-overlay {
  z-index: 1000000;
}

#astra-iframe-container {
  width: 600px !important;
  max-width: var(--small-screen-max-content-width);
  height: 80% !important;
}

#finix-form .finix-form-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .field-holder {
    display: flex;
    flex-direction: column;
    margin-top: 0 !important;

    label {
      @include text-base-medium;
      color: var(--charcoal);
      margin-bottom: 8px;
    }

    .field {
      margin-top: 0 !important;
      height: 48px;

      iframe {
        height: inherit !important;
      }
    }

    .validation {
      @include text-xs-regular;
      color: var(--error-red);
      line-height: 24px;
    }
  }

  & > .field-array:nth-child(4) {
    display: none !important;
  }
}

.focused-field-name #name_validation,
.focused-field-number #number_validation,
.focused-field-expiration_date #expiration_date_validation,
.focused-field-security_code #security_code_validation,
.focused-field-address_postal_code #address_postal_code_validation {
  display: none;
}

.action-container {
  display: flex;

  &.split-equally {
    flex-direction: row-reverse;
    gap: 24px;

    > * {
      flex: 1 0;
    }
  }
}
