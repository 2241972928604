@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

@include mq(629, max) {
  .bank-statement {
    .section-header {
      .section-title {
        @include text-xl-semibold;
      }
    }

    .statements {
      row-gap: 12px;

      .statement-item {
        padding: 24px 16px;

        .statement-details {
          row-gap: 8px;

          .year,
          .month {
            @include text-base-medium;
          }
        }
      }
    }
  }
}
