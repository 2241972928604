@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

.manage-cards {
  unit-elements-card {
    + unit-elements-card,
    + .primary-button {
      margin-top: 32px;
    }
  }

  .primary-button {
    max-width: 350px;
  }
}

@include mq(629, max) {
  .manage-cards {
    unit-elements-card {
      margin-top: 4px;
      padding: 20px 16px;
      border-radius: var(--rounded-md);
      background: var(--white);
      max-width: 350px;

      + unit-elements-card,
      + .primary-button {
        margin-top: 20px;
      }
    }
  }
}
