@import "../../Assets/Styles/typography-mixins.scss";

.unclaimed {
  position: relative;

  .unclaimed-background {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: var(--primary-400);
    overflow: hidden;

    .unclaimed-page-background {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0%);
    }
  }

  .page-content {
    position: relative;
    z-index: 3;
    min-height: 100vh;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .unclaimed-total-card,
    .unclaimed-list-wrapper {
      background-color: var(--primary-200);
      border-radius: var(--rounded-lg);
      box-shadow: var(--card-drop-shadow);
    }

    .unclaimed-total-card,
    .unclaimed-list-card {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .unclaimed-total-card {
      padding: 32px 24px;
      justify-content: center;
    }

    .unclaimed-list-wrapper {
      overflow: hidden;
      --initial-height: 0;
      animation: expand-animation 1.75s ease-in-out 0.25s both;
    }

    .unclaimed-list-card {
      padding: 24px;
      row-gap: 16px;

      .unclaimed-list-item {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .unclaimed-info {
          margin-right: 16px;
        }

        .reason,
        .date,
        .amount {
          @include text-xl-medium;
          color: var(--charcoal);
        }

        .reason {
          margin-bottom: 8px;
        }

        .date {
          opacity: 0.6;
        }

        .date,
        .amount {
          margin-bottom: 0;
        }
      }
    }

    .claim-cta-button {
      @include text-base-medium;
      background: var(--bg-primary);
      color: var(--text-primary);
      animation: slide-bottom-to-top-fade-in 0.5s ease-in-out 2s both;

      .MuiCircularProgress-root {
        height: 20px !important;
        width: 20px !important;
      }

      &:not(:disabled):hover {
        background: var(--bg-secondary);
      }

      &:disabled {
        background: var(--amazon);
      }
    }
  }
}
