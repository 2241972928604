.form-switch.MuiSwitch-root {
  height: 14px;
  width: 38px;
  padding: 0;
  overflow: visible;

  .MuiSwitch-switchBase {
    padding: 0;
    top: -3px;
    color: var(--medium-grey);

    .MuiSwitch-thumb {
      box-shadow: none;
    }

    &.Mui-checked {
      color: var(--stake-blue);

      + .MuiSwitch-track {
        background-color: var(--secondary-100);
      }
    }
  }

  .MuiSwitch-track {
    background: var(--HR);
    opacity: 1 !important;
  }
}
