@import "../../Assets/Styles/mixins.scss";

.banking {
  .banking-details {
    .banking-activity.skeleton {
      .activity-list {
        background-color: var(--loader-bg-dark-secondary);
        opacity: 0.7;

        .activity-divider {
          border-color: var(--loader-bg-dark-primary);
        }
      }
    }
  }

  .earn-more-cards.skeleton {
    .earn-more-card {
      &:hover {
        background: var(--white);
      }
    }
  }
}

@include mq(629, max) {
  .banking {
    .banking-details {
      .banking-account-info.skeleton {
        height: 200px !important;
      }

      .banking-action-buttons.skeleton {
        .MuiSkeleton-root {
          height: 48px !important;
        }
      }
    }

    .earn-more-cards.skeleton {
      .earn-more-card {
        gap: 48px !important;

        .title {
          min-width: 80px;
          max-width: 120px;
        }

        .description {
          min-width: 120px;
          max-width: 180px;
        }

        .button {
          min-width: 80px;
          max-width: 120px;
        }
      }
    }
  }
}
