.terms-of-service {
  .modal-dialog-header {
    display: none;
  }
  
  .modal-image {
    height: 250px;
    object-fit: cover;
  }
}
