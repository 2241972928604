@import "../../Assets/Styles/mixins.scss";
@import "../../Assets/Styles/typography-mixins.scss";

@include mq(629, max) {
  .unclaimed {
    .page-content {
      .unclaimed-total-card {
        padding: 16px 24px;
      }

      .unclaimed-list-wrapper {
        --initial-height: 88px;
      }

      .unclaimed-list-card {
        padding: 16px;

        .unclaimed-list-item {
          .reason,
          .date,
          .amount {
            @include text-lg-medium;
          }
        }
      }
    }
  }
}
