@import "../../../../Assets/Styles/typography-mixins.scss";
@import "../../../../Assets/Styles/mixins.scss";

.modal-activity-list {
  .activity-item {
    .row-1 {
      margin-bottom: 8px;
    }

    .activity-name,
    .activity-amount,
    .activity-date,
    .balance-after-activity {
      @include text-xl-medium;
    }

    .activity-name,
    .activity-amount {
      color: var(--charcoal);
    }

    .activity-date,
    .balance-after-activity {
      color: var(--medium-grey);
    }
  }

  .activity-divider {
    margin: 16px 0;
    border-color: var(--HR);
    opacity: 1;
  }
}

@include mq(629, max) {
  .modal-activity-list {
    .activity-item {
      .activity-name,
      .activity-amount,
      .activity-date,
      .balance-after-activity {
        @include text-lg-medium;
      }
    }

    .activity-divider {
      margin: 8px 0;
    }
  }
}
