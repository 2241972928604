@import "./mixins.scss";
@import "./typography-mixins.scss";

.username-field,
.otp-field {
  label {
    display: block;
    margin-bottom: 16px;
    @include text-lg-regular;
    color: var(--text-primary);
  }

  .MuiInputBase-root {
    display: block;
    background: var(--yankees-blue);
    border-radius: var(--rounded-lg);

    input {
      box-sizing: border-box;
      height: 64px;
      @include text-2xl-semibold;
      color: var(--text-primary);

      &:-webkit-autofill {
        transition: background-color 0s 600000s, color 0s 600000s !important;
      }

      &.Mui-disabled {
        -webkit-text-fill-color: var(--text-primary);
      }
    }

    fieldset {
      border: none;
    }
  }

  &.light-theme {
    label {
      color: var(--charcoal);
    }

    .MuiInputBase-root {
      background: var(--white);
      border: 1px solid var(--HR);

      input {
        color: var(--charcoal);
      }
    }
  }
}

.otp-field {
  .MuiOtpInput-Box {
    gap: 20px;
    justify-content: space-between;

    position: relative;

    .MuiInputBase-root {
      width: 100%;
      max-width: 64px;
      min-width: 40px;
      aspect-ratio: 1 / 1;

      input {
        height: 100%;
        padding: 0;

        &[type="number"] {
          -moz-appearance: textfield;
          appearance: textfield;
        }

        &[type="number"]::-webkit-inner-spin-button,
        &[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          appearance: none;
          margin: 0;
        }
      }
    }

    .MuiOtpInput-TextField-3 {
      position: static;
      margin-right: 36px;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 6px);
        height: 2px;
        width: 12px;
        border-radius: 1px;
        background-color: var(--text-primary);
      }
    }
  }

  &.light-theme {
    .MuiOtpInput-TextField-3 {
      &::after {
        background-color: var(--HR);
      }
    }
  }
}

.app-utility-modal .otp-field {
  .MuiOtpInput-Box {
    .MuiInputBase-root {
      max-width: 54px;
    }
  }
}

.text-field {
  label {
    @include text-base-medium;
    color: var(--charcoal);
    margin-bottom: 6px;
  }

  .description {
    display: block;
    @include text-xs-regular;
    color: var(--medium-grey);
    margin: -2px 0 6px 0;
  }

  .input-icon-wrapper {
    position: relative;
    height: 48px;

    .MuiInputBase-root {
      width: 100%;
      padding: 0;

      &.MuiInputBase-readOnly {
        pointer-events: none;
      }

      input {
        border-radius: var(--rounded-md);
        background: var(--white);

        box-sizing: border-box;
        border: 1px solid var(--HR);
        height: 48px;
        padding: 12px;

        @include text-base-medium;
        color: var(--charcoal);
        text-overflow: ellipsis;

        &::placeholder {
          color: var(--medium-grey);
          opacity: 0.6;
        }
      }

      fieldset {
        border: none !important;
      }
    }

    .right-icon {
      position: absolute;
      z-index: 2;
      top: 12px;
      right: 12px;
      height: 24px;
      width: 24px;
      cursor: pointer;

      + .MuiInputBase-root input {
        padding-right: 48px;
      }
    }
  }

  .error-message {
    display: block;
    @include text-xs-regular;
    color: var(--error-red);
    margin-top: 6px;
  }

  .helper-text {
    display: block;
    @include text-sm-regular;
    color: var(--medium-grey);
    margin-top: 6px;
  }
}

.amount-field {
  .MuiFormControl-root {
    .MuiInputBase-root {
      padding: 0;

      input {
        padding: 0;
        border: 0;
        height: auto;

        @include text-6xl-semibold;
        color: var(--charcoal);
        text-align: center;

        &[type="number"] {
          -moz-appearance: textfield;
          appearance: textfield;
        }

        &[type="number"]::-webkit-inner-spin-button,
        &[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          appearance: none;
          margin: 0;
        }
      }

      fieldset {
        border: none !important;
      }
    }
  }
}

@include mq(629px, max) {
  .otp-field {
    .MuiOtpInput-Box {
      gap: 10px;
      justify-content: space-between;
      margin: 0 auto;

      .MuiOtpInput-TextField-3 {
        margin-right: 28px;

        &::after {
          right: -24px;
        }
      }
    }
  }

  .amount-field {
    .MuiFormControl-root {
      .MuiInputBase-root {
        input {
          @include text-5xl-semibold;
        }
      }
    }
  }
}

@include mq(384px, max) {
  .otp-field {
    .MuiOtpInput-Box {
      .MuiOtpInput-TextField-3 {
        margin-right: 0;

        &::after {
          display: none;
        }
      }
    }
  }
}
