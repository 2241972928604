@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

@include mq(629, max) {
  .direct-deposit {
    .payrolls {
      &.no-records {
        height: 120px;
      }
    }
  }
}
