@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

@include mq(629, max) {
  .banking-limits {
    .banking-limit-item {
      padding: 16px;

      .title {
        @include text-lg-medium;
      }
    }
  }
}
