@import "./typography-mixins.scss";
@import "./mixins.scss";

.custom-calendar {
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
    padding: 10px;

    & h2 {
      @include text-xl-medium;
      color: var(--charcoal);
      margin: 0;
    }

    & button {
      display: flex;
      align-items: center;
      padding: 10px;

      &:disabled {
        opacity: 0.4;
      }
    }
  }

  .month-calendar {
    margin: 10px;
    width: calc(100% - 20px);

    display: flex;
    flex-direction: column;

    .weekdays {
      @include flex-space-between;

      .weekday {
        @include flex-center;
        @include text-sm-medium;
        color: var(--medium-grey);
        height: 36px;
        width: 40px;
      }
    }

    .month-dates {
      .month-week {
        @include flex-space-between;
        margin-top: 12px;

        .date {
          @include flex-center;
          height: 36px;
          width: 40px;
          @include text-base-medium;
          color: var(--charcoal);

          [role="button"] {
            cursor: default;
          }

          &.badge {
            position: relative;

            [role="button"] {
              cursor: pointer;
            }

            &.green::after {
              background-color: var(--success-green);
            }

            &.blue::after {
              background-color: var(--stake-blue);
            }

            &::after {
              content: "";
              position: absolute;
              left: 50%;
              bottom: 0;
              transform: translateX(-50%);
              width: 8px;
              height: 8px;
              border-radius: var(--rounded-full);
            }
          }

          &.disabled,
          &.unavailable {
            opacity: 0.3;
          }
        }
      }
    }
  }
}

.calendar-legend {
  @include flex-start;
  flex-wrap: wrap;
  gap: 16px;

  .legend-item {
    @include flex-start;
    gap: 6px;

    @include text-base-semibold;

    &.green {
      color: var(--success-green);
    }

    &.blue {
      color: var(--stake-blue);
    }

    .legend-badge {
      height: 12px;
      width: 12px;
      border-radius: var(--rounded-full);
      background: currentcolor;
    }
  }
}

@include mq(629, max) {
  .custom-calendar {
    .month-calendar {
      margin: 10px 4px;
      width: calc(100% - 8px);
    }
  }
}
