@import "../../../Assets/Styles/animations.scss";

.trust-content {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .content-card {
    padding: 24px;
    border-radius: var(--rounded-lg);
    background-color: var(--bg-secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .stake-free-description {
    max-width: 357px;
  }

  .reviews {
    display: flex;
    flex-direction: column-reverse;

    .carousel-control-prev,
    .carousel-control-next {
      width: auto;
      top: calc(50% - 16px);
      bottom: auto;
      opacity: 1;
    }

    .carousel-control-prev {
      left: 8px;
    }
    .carousel-control-next {
      right: 8px;
    }

    .carousel-indicators {
      position: relative;
      margin: 32px 0 0 0;

      button {
        height: 8px;
        width: 8px;
        border: none;
        border-radius: var(--rounded-sm);
      }
    }

    .review-item {
      max-width: 357px;
      margin: 0 auto;

      .reviewer-info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .reviewer-image {
          width: 80px;
          height: 80px;
          border-radius: var(--rounded-full);
        }

        .reviewer-details {
          display: flex;
          flex-direction: column;
          width: calc(100% - 80px - 12px);

          h2 {
            margin-bottom: 6px;
          }
        }
      }

      .divider {
        margin: 12px 0;
        height: 1px;
        background-color: var(--HR);
        opacity: 0.2;
      }

      .review-text {
        min-height: 100px;
      }
    }
  }

  .animate-in-order {
    animation: slide-bottom-to-top-fade-in 0.6s ease-in-out 0.7s both;

    &.order-1 {
      animation-delay: 0.7s;
    }
  
    &.order-2 {
      animation-delay: 1.4s;
    }
  
    &.order-3 {
      animation-delay: 2.1s;
    }
  
    &.order-4 {
      animation-delay: 2.8s;
    }
  
    &.order-5 {
      animation-delay: 3.5s;
    }
  }
}

.trust-cta-button {
  animation: slide-bottom-to-top-fade-in 0.6s ease-in-out 4.2s both;

  &.order-5 {
    animation-delay: 3.5;
  }
}
