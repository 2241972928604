@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

.direct-deposit {
  .payrolls {
    .payroll,
    &.no-records {
      padding: 20px 16px;
      background: var(--white);
      border-radius: var(--rounded-md);
      border: 1px solid var(--HR);
    }

    .payroll {
      .header,
      .allocation {
        @include flex-space-between;
        gap: 24px;
      }

      .header {
        margin-bottom: 12px;
        .payroll-details {
          .company-logo {
            height: 40px;
            border-radius: var(--rounded-full);
            margin-bottom: 8px;
          }

          .status-badge {
            @include flex-center;
            padding: 4px 8px;
            gap: 4px;
            border-radius: var(--rounded-full);
            border: 1px solid;
            background: var(--white);
            @include text-xs-medium;
            color: var(--charcoal);
            width: fit-content;

            &.success {
              border-color: var(--success-green);

              .badge-icon {
                width: 12px;
              }
            }

            &.pending {
              border-color: var(--stake-blue);
            }

            &.disconnected {
              border-color: var(--error-red);
            }

            .badge-icon {
              height: 12px;
            }
          }
        }
      }

      .allocation {
        margin-top: 12px;
        .details {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .amount {
            @include text-base-medium;
            color: var(--charcoal);
          }

          .updated-on {
            margin-top: 4px;
            @include text-xs-regular;
            color: var(--medium-grey);
          }
        }

        .update {
          display: flex;
          align-items: center;
        }
      }

      + .payroll {
        margin-top: 24px;
      }

      .MuiDivider-root {
        opacity: 1;
        border-color: var(--HR);
      }

      .MuiCircularProgress-root,
      .icon {
        height: 24px !important;
        width: 24px !important;

        &.alert-icon path {
          fill: var(--error-red);
        }
      }

      .MuiCircularProgress-root {
        color: var(--stake-blue);
      }

      button.update path {
        fill: var(--stake-blue);
      }
    }

    &.no-records {
      margin-top: 24px;
      @include text-xl-medium;
      color: var(--charcoal);
      height: 148px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
