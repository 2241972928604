@import "../../Assets/Styles/mixins.scss";

@include mq(629, max) {
  .login,
  .login-with-unique-id {
    justify-content: flex-start;

    .form-card {
      padding: 24px 16px;
      row-gap: 36px;
    }
  }
}
