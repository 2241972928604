.custom-radio-button {
  appearance: none;
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: var(--rounded-full);
  background-color: var(--white);
  border: 3px solid var(--white);
  outline: 2px solid var(--medium-grey);
  margin: 2px;
  flex-shrink: 0;

  &.checked,
  &:checked {
    background-color: var(--stake-blue);
    outline-color: var(--stake-blue);
  }
}
