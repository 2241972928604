@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

.connect-your-bank,
.bank-account-item {
  border-radius: var(--rounded-md);
  background: var(--white);
  border: 1px solid var(--HR);
}

.connect-your-bank {
  overflow: hidden;

  .earn-more-card {
    background: transparent;
    border-radius: 0;
    padding: 24px;

    .card-content {
      max-width: calc(100% - 135px);

      .texts {
        .title {
          @include text-xl-medium;
          white-space: pre-line;
        }
      }

      .button {
        width: 152px;
        @include text-xl-medium;
      }
    }
  }
}

.bank-account-item {
  @include flex-space-between;
  gap: 16px;
  padding: 16px 12px;

  .info {
    @include flex-space-between;
    gap: 12px;

    .bank-logo {
      height: 32px;
      border-radius: var(--rounded-full);
      border: 1px solid var(--HR);
    }

    .bank-logo-placeholder {
      @include text-2xl-semibold;
      color: var(--charcoal);
    }

    .account-details {
      .account-name {
        @include text-sm-medium;
        color: var(--charcoal);
        margin-bottom: 0;
      }

      .account-number {
        @include text-xxs-regular;
        color: var(--medium-grey);
      }
    }
  }

  .actions {
    @include flex-space-between;
    gap: 16px;

    .MuiCircularProgress-root {
      color: var(--error-red);
    }

    .delete {
      display: flex;
      height: 20px !important;
      width: 20px !important;

      svg {
        height: inherit;
        width: inherit;
      }
    }

    button.set-default,
    .default-badge {
      padding: 4px 6px;
      @include text-xs-medium;
    }

    button.set-default {
      color: var(--stake-blue);
    }

    .default-badge {
      border-radius: var(--rounded-sm);
      color: var(--white);
      background: var(--stake-blue);
    }
  }

  &.selectable:not(.selected) {
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: var(--bs-gray-100);
    }
  }

  + .bank-account-item {
    margin-top: 16px;
  }

  + button.add-account {
    display: flex;
    gap: 8px;
    margin: 16px 0 0 12px;
    @include text-base-medium;
    color: var(--stake-blue);
  }
}
