@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

.debit-card-item {
  border-radius: var(--rounded-md);
  background: var(--white);
  border: 1px solid var(--HR);
  padding: 20px 16px;

  .card-details-container,
  .status-container {
    @include flex-space-between;
    gap: 16px;
  }

  .card-details-container {
    .card-info {
      display: flex;
      align-items: center;
      gap: 12px;

      .card-logo {
        height: 32px;

        svg {
          height: inherit;
        }
      }

      .card-details {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 4px;

        .card-type {
          @include text-sm-medium;
          color: var(--charcoal);
        }

        .card-expiry {
          @include text-xs-regular;
          color: var(--medium-grey);
        }
      }
    }

    .actions {
      @include flex-center;
      gap: 12px;

      button.set-default,
      .status-badge {
        padding: 4px 6px;
        @include text-xs-medium;
      }

      button.set-default {
        color: var(--stake-blue);
      }

      .status-badge {
        border-radius: var(--rounded-sm);
        color: var(--white);

        &.approved {
          background-color: var(--success-green);
        }

        &.pending {
          background-color: var(--stake-blue);
        }

        &.rejected {
          background-color: var(--error-red);
        }

        &.flagged,
        &.expired {
          background-color: var(--medium-grey) !important;
        }
      }

      button.delete {
        display: flex;
        height: 20px;
        width: 20px;

        svg {
          height: inherit;
          width: inherit;
        }
      }

      .deletion-in-progress {
        height: 20px !important;
        width: 20px !important;
        color: var(--error-red);
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 12px 0;
    background-color: var(--HR);
  }

  &.selectable:not(.selected) {
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: var(--bs-gray-100);
    }
  }

  + .debit-card-item {
    margin-top: 16px;
  }

  + button.add-account {
    display: flex;
    gap: 8px;
    margin: 16px 0 0 12px;
    @include text-base-medium;
    color: var(--stake-blue);
  }
}

.no-debit-cards {
  @include flex-center;
  flex-direction: column;

  .card-icon {
    height: 80px;
  }

  .no-cards-title {
    @include text-xl-semibold;
    color: var(--charcoal);
  }

  .no-cards-message {
    @include text-base-regular;
    color: var(--medium-grey);
    text-align: center;
  }

  .primary-button {
    @include text-lg-medium;
    width: 280px;
  }
}
