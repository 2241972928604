$colors: --white, --charcoal, --HR, --robin-egg, --yankees-blue, --off-white, --medium-grey, --stake-blue, --smoke,
  --success-green, --stake-yellow, --error-red, --ghost-white, --amazon, --alice-blue, --new-car, --bright-gray,
  --lotion, --bg-primary, --bg-secondary, --text-primary, --text-secondary, --primary-0, --primary-50, --primary-100,
  --primary-200, --primary-300, --primary-400, --primary-500, --primary-600, --primary-700, --primary-800, --primary-900,
  --secondary-0, --secondary-50, --secondary-100, --secondary-200, --secondary-300, --secondary-400, --secondary-500,
  --secondary-600, --secondary-700, --secondary-800, --secondary-900, --loader-bg-light-primary,
  --loader-bg-light-secondary, --loader-bg-dark-primary, --loader-bg-dark-secondary, --loader-bg-dark-tertiary;

@each $current-color in $colors {
  .color#{$current-color} {
    color: var($current-color) !important;
  }

  .bg#{$current-color} {
    background-color: var($current-color) !important;
  }
}
