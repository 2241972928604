.app-utility-modal.flex-dialog.rent-tracker-dialog .MuiPaper-root {
  padding: 0;

  .modal-dialog-header {
    padding: 32px 24px 24px;
    z-index: 3;
    background: var(--white);

    .close-button {
      top: 24px;
      right: 16px;
    }
  }

  #rent-tracker-items {
    margin-bottom: 32px;
    max-height: calc(100vh - 64px - 88px);
    max-height: calc(100dvh - 64px - 88px);
    overflow-y: auto;
    position: relative;

    .rent-tracker-item {
      + .rent-tracker-item {
        margin-top: 24px;
      }

      &.is-sticky .rent-tracker-header {
        box-shadow: 0 8px 5px -5px rgba(7, 7, 7, 0.16);
      }

      .rent-tracker-header {
        position: sticky;
        top: 0;
        left: 0;
        background-color: var(--white);
        z-index: 2;
        padding: 0 24px 8px;
      }

      .rent-tracker-row {
        padding: 8px 24px;

        &::after {
          left: 24px;
          right: 24px;
        }
      }

      .rent-tracker-row:nth-child(2) {
        padding-top: 0;
      }
    }
  }
}
