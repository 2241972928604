@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

.recurring-deposit-card {
  background: var(--white);
  border-radius: var(--rounded-md);
  border: 1px solid var(--HR);
  padding: 16px;

  .card-row {
    @include flex-space-between;
    gap: 24px;

    & + .MuiDivider-root {
      border: 0.8px solid var(--HR);
      margin: 16px 0;
      opacity: 1;
    }
  }
}
