@import "./typography-mixins.scss";
@import "./mixins.scss";

.primary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  height: 44px;
  width: 100%;
  padding: 12px 24px;
  border-radius: var(--rounded-md);

  background-color: var(--primary-400);
  color: var(--charcoal);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color, color, border;

  @include text-base-medium;

  &:disabled {
    opacity: 0.4;
  }

  &:not(:disabled):hover {
    background-color: var(--primary-500);
  }

  &.blue-theme {
    background-color: var(--stake-blue);
    color: var(--text-primary);

    &:not(:disabled):hover {
      background-color: var(--secondary-500);
    }
  }

  &.secondary-colors {
    background-color: var(--bright-gray);

    &:not(:disabled):hover {
      background: var(--HR);
    }
  }

  &.red-theme {
    background-color: var(--error-red);
    color: var(--text-primary);

    &:not(:disabled):hover {
      background-color: var(--error-red-hovered);
    }
  }
}

.outline-button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  width: 100%;
  padding: 12px 24px;
  border-radius: var(--rounded-md);
  border: 1px solid var(--charcoal);
  color: var(--charcoal);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color, color, border;

  @include text-base-medium;

  .icon {
    height: 24px;
    width: 24px;

    path {
      fill: var(--charcoal);
      stroke-width: 1px;
    }
  }

  &:disabled {
    opacity: 0.4;
  }

  &:not(:disabled):hover {
    background-color: var(--smoke);
  }

  &.light-theme {
    border: 1px solid var(--smoke);
    color: var(--smoke);

    .icon {
      path {
        fill: var(--smoke);
      }
    }

    &:not(:disabled):hover {
      color: var(--charcoal);
      background: var(--smoke);
    }
  }
}

.primary-button,
.outline-button {
  .MuiCircularProgress-root {
    height: 16px !important;
    width: 16px !important;
    color: currentColor;
  }
}

.card-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;

  min-height: 52px;
  height: 100%;
  width: 100%;
  padding: 16px 24px;
  border-radius: var(--rounded-lg);
  background-color: var(--white);
  transition: background-color 0.15s ease-in-out;

  .content {
    display: flex;
    align-items: center;
    column-gap: 8px;

    .icon {
      height: 16px;
      width: 16px;

      path {
        fill: var(--stake-blue);
      }
    }

    .title {
      @include text-base-medium;
      color: var(--charcoal);
      text-align: left;
    }
  }

  .chevron {
    height: 16px;
    width: 16px;
    transition: transform 0.15s ease-in-out;
  }

  &:hover {
    background-color: var(--bs-gray-100);

    .chevron {
      transform: translatex(6px);
    }
  }

  &.logout {
    .content {
      .icon {
        path {
          fill: var(--error-red);
          stroke: var(--error-red);
        }
      }

      .title {
        color: var(--error-red);
      }
    }
  }
}
