@import "../../../Assets/Styles/typography-mixins.scss";
@import "../../../Assets/Styles/mixins.scss";

.setup-stake-pay-2 {
  .step-indicator {
    margin: 0 24px;
  }

  .modal-dialog-content {
    flex: 1 0;
  }

  .stake-banking-widget-mockup {
    border-radius: var(--rounded-xl);
    background: var(--bg-primary);
    padding: 28px;

    .account-info {
      @include flex-center;
      flex-direction: column;

      padding: 32px 24px;
      border-radius: var(--rounded-md);
      background: var(--bg-secondary);

      .balance-label {
        display: block;
        @include text-sm-regular;
        color: var(--medium-grey);
        text-align: center;
      }

      .odometer {
        overflow: hidden;

        .odometer-inside {
          .odometer-digit:nth-child(3) .odometer-value {
            position: relative;
            transform: translateY(14px);

            &::before {
              content: "0";
              width: 100%;
              height: 28px;
              position: absolute;
              left: 0;
              top: -28px;
            }

            &:after {
              content: "";
              width: 100%;
              height: 28px;
              position: absolute;
              left: 0;
              top: -14px;
              background: linear-gradient(
                180deg,
                var(--bg-secondary) 0%,
                transparent 25%,
                transparent 50%,
                transparent 75%,
                var(--bg-secondary) 100%
              );
            }
          }
        }
      }

      .MuiDivider-root {
        width: calc(100% + 48px);
        border: 1px solid var(--medium-grey);
        opacity: 0.3;
        margin: 24px -24px;
      }

      .account-number,
      .routing-number {
        width: 100%;
        @include flex-space-between;

        .label,
        .value {
          @include text-sm-regular;
          color: var(--text-primary);
        }
      }
    }

    .banking-action-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      gap: 16px;

      .add-cash-button,
      .cash-out-button {
        flex-grow: 1;
        @include text-sm-regular;
        color: var(--text-primary);
        text-align: center;

        border-radius: var(--rounded-md);
        background: var(--bg-secondary);
        padding: 8px;
      }
    }
  }
}

@include mq(629, max) {
  .setup-stake-pay-2 {
    .step-indicator {
      margin: 0 16px;
    }

    .stake-banking-widget-mockup {
      padding: 16px;

      .account-info {
        padding: 16px 12px;

        .MuiDivider-root {
          margin: 16px -12px;
        }
      }
    }
  }
}
