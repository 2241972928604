@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

.add-cash,
.cash-out {
  .content {
    gap: 64px;
  }
}

.add-cash,
.cash-out,
.recurring-deposit.content {
  .top-container {
    @include flex-start;
    flex-direction: column;

    .daily-limit-label {
      @include text-lg-medium;
      color: var(--medium-grey);
    }

    .max-no-of-transfers-badge {
      padding: 4px 12px;
      border-radius: var(--rounded-full);
      color: var(--stake-blue);
      @include text-xs-medium;
      @include background-opacity(var(--alice-blue), 70%);
    }
  }

  .bottom-container {
    .transfer-types {
      @include flex-space-between;
      align-items: normal;
      gap: 24px;

      .transfer-type {
        position: relative;
        flex: 1 0;
        padding: 24px 32px;
        border: 1px solid var(--HR);
        border-radius: var(--rounded-md);
        background-color: var(--white);

        @include flex-start;
        flex-direction: column;

        &.ach {
          .icon path {
            fill: var(--white);
          }
        }

        &.selected {
          border-color: var(--stake-blue);

          .icon-wrapper {
            background-color: var(--stake-blue);
          }
        }

        .icon-wrapper {
          height: 32px;
          width: 32px;
          border-radius: var(--rounded-full);
          background: var(--medium-grey);
          @include flex-center;

          .icon {
            height: 16px;
            width: 16px;
          }
        }

        .title {
          @include text-lg-medium;
          color: var(--charcoal);
          text-align: center;
          margin: 8px 0 2px 0;
          white-space: pre-line;
        }

        .description {
          @include text-sm-regular;
          color: var(--medium-grey);
          text-align: center;
          white-space: pre-line;
        }

        .restricted-badge {
          position: absolute;
          top: 0;
          right: 0;
          padding: 4px 12px;
          border-radius: 0px var(--rounded-sm);
          @include background-opacity(var(--medium-grey), 10%);
          @include text-xs-medium;
          color: var(--medium-grey);
        }

        .MuiCircularProgress-root {
          margin-top: 8px;
          height: 20px !important;
          width: 20px !important;
          color: var(--medium-grey);
        }
      }
    }

    .details {
      border-radius: var(--rounded-md);
      border: 1px solid var(--HR);
      background: var(--white);
      padding: 8px 0;

      .detail-row {
        padding: 16px;

        @include flex-space-between;
        gap: 16px;

        .detail-label,
        .detail-value {
          @include text-lg-medium;
        }

        .detail-label {
          color: var(--medium-grey);
        }

        .detail-value {
          color: var(--charcoal);
          @include flex-start;
          text-align: right;
          gap: 16px;

          .edit {
            flex-shrink: 0;
          }
        }

        .info {
          @include text-base-regular;
          color: var(--medium-grey);
          max-width: 390px;
        }

        + .detail-row {
          border-top: 1px solid var(--HR);
        }
      }
    }
  }
}
