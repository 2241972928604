@import "./mixins.scss";
@import "./typography-mixins.scss";

@include mq(991, max) {
  .app-utility-container {
    display: block;

    .app-utility-sidebar {
      display: none;
    }

    header,
    .app-utility-navbar {
      display: flex;
    }

    .app-content {
      padding-top: 68px;
      min-height: calc(100vh - 72px);
      min-height: calc(100dvh - 72px);
    }
  }

  .section-header {
    + .content,
    + .loading-content {
      min-height: calc(100vh - 292px);
      min-height: calc(100dvh - 292px);
    }
  }

  .card-section-wrapper {
    padding: 28px 0px;

    .card-section {
      min-height: calc(100vh - 68px - 56px - 72px);
      min-height: calc(100dvh - 68px - 56px - 72px);
    }
  }
}

@include mq(767, max) {
  header {
    padding: 28px 24px;
  }

  .max-width-container {
    width: var(--medium-screen-max-content-width);
  }

  section {
    padding: 28px 0;
  }

  .section-header {
    + .content,
    + .loading-content {
      min-height: calc(100vh - 252px);
      min-height: calc(100dvh - 252px);
    }
  }
}

@include mq(629, max) {
  header {
    padding: 24px 20px;
  }

  .max-width-container {
    width: var(--small-screen-max-content-width);
  }

  .app-utility-container {
    header {
      padding: 24px 20px 8px 20px;
    }

    .app-content {
      padding-top: 64px;
    }
  }

  section {
    padding: 24px 0;
  }

  .section-header {
    .see-all-button {
      @include text-sm-medium;
    }

    + .content,
    + .loading-content {
      min-height: calc(100vh - 224px);
      min-height: calc(100dvh - 224px);
    }
  }

  .card-section-wrapper {
    padding: 20px 0;

    .card-section {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
      min-height: calc(100vh - 64px - 40px - 72px);
      min-height: calc(100dvh - 64px - 40px - 72px);
    }
  }
}
