@import "../../Assets/Styles/mixins.scss";
@import "../../Assets/Styles/typography-mixins.scss";

.earn-more-card {
  position: relative;
  background-color: var(--white);
  border-radius: var(--rounded-lg);
  padding: 16px 24px;
  min-height: 156px;
  overflow: hidden;
  display: flex;
  height: 100%;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    .texts {
      margin-bottom: 16px;
    }

    .title {
      @include text-base-medium;
      color: var(--charcoal);
      margin-bottom: 4px;
    }

    .description {
      @include text-xs-regular;
      color: var(--charcoal);
      opacity: 0.4;
      width: 176px;
    }

    .button {
      @include text-sm-medium;
      color: var(--charcoal);
      border: 1px solid var(--charcoal);
      border-radius: var(--rounded-md);
      padding: 12px 16px;
      width: 120px;

      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

      .MuiCircularProgress-root {
        height: 16px !important;
        width: 16px !important;
        color: currentColor;
      }
    }

    .custom-stepper {
      max-width: calc(100% - 96px);

      .MuiStep-root {
        .MuiStepLabel-root {
          .MuiStepLabel-iconContainer .step-icon {
            height: 10px;
            width: 10px;
            background-color: var(--medium-grey);
          }

          .MuiStepLabel-labelContainer .MuiStepLabel-label {
            @include text-xs-medium;
            color: var(--charcoal);
          }
        }

        &.Mui-completed {
          .MuiStepLabel-root {
            .MuiStepLabel-iconContainer .step-icon {
              background-color: var(--charcoal);
            }

            .MuiStepLabel-labelContainer .MuiStepLabel-label {
              color: var(--charcoal);
            }
          }
        }

        &.MuiStep-vertical {
          &:not(:first-child) .MuiStepLabel-root::before,
          &:not(:last-child) .MuiStepLabel-root::after {
            left: 4px;
            height: calc(50% - 5px);
            background-color: var(--medium-grey);
          }

          &.Mui-completed {
            &:not(:first-child) .MuiStepLabel-root::before {
              background-color: var(--charcoal);
            }

            &:not(.active):not(:last-child) .MuiStepLabel-root::after {
              background-color: var(--charcoal);
            }
          }
        }
      }

      .MuiStepConnector-root {
        &.MuiStepConnector-vertical {
          margin: 0 0 0 4px;

          .MuiStepConnector-line {
            border-left: 2px solid var(--medium-grey);
            min-height: 10px;
          }
        }

        &.MuiStepConnector-vertical.Mui-completed {
          .MuiStepConnector-line {
            border-left-color: var(--charcoal);
          }
        }
      }
    }
  }

  .illustration {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:hover {
    background: var(--bs-light);
    .card-content {
      .button {
        background-color: var(--primary-400);
        border-color: var(--primary-400);
      }
    }
  }
}

@include mq(629, max) {
  .earn-more-card {
    padding: 16px;
    min-height: 132px;

    .card-content {
      .button {
        border-radius: var(--rounded-lg);
        padding: 8px 16px;
      }
    }
  }
}
