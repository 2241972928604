@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

.personal-information {
  .form-card {
    @include styled-card;
    padding: 24px;

    .text-field:not(.no-action) .input-icon-wrapper .MuiInputBase-root.MuiInputBase-readOnly {
      pointer-events: all;

      input {
        cursor: pointer;
      }
    }
  }
}
