@import "../../Assets/Styles/typography-mixins.scss";
@import "../../Assets/Styles/mixins.scss";

.banking-limits {
  .banking-limit-item {
    border-radius: var(--rounded-md);
    border: 1px solid var(--HR);
    background: var(--white);
    padding: 24px;

    .title {
      @include text-xl-medium;
      color: var(--charcoal);
    }

    .info {
      @include flex-space-between;
      gap: 16px;
    }

    .limit-label,
    .remaining-label {
      @include text-sm-regular;
    }

    .limit-label {
      color: var(--medium-grey);
      text-align: right;
    }

    .remaining-label {
      color: var(--charcoal);
    }

    .MuiLinearProgress-root {
      margin-top: 4px;
      height: 12px;
      border-radius: var(--rounded-full);
      @include background-opacity(var(--HR), 80%);

      .MuiLinearProgress-bar {
        border-radius: var(--rounded-full);
        background: var(--stake-blue);
      }
    }
  }
}
