@import "../../../Assets/Styles/typography-mixins.scss";
@import "../../../Assets/Styles/mixins.scss";

.has-transfer-info {
  .modal-dialog-content {
    flex: 1 0;
  }

  .transfer-detail {
    @include flex-space-between;
    gap: 20px;
    padding: 4px 0;

    .detail-label {
      @include text-base-regular;
      color: var(--medium-grey);
    }

    .detail-value {
      @include text-base-medium;
      color: var(--charcoal);
      text-align: right;
    }

    & + .transfer-detail {
      margin-top: 6px;
    }
  }

  .disclaimer {
    @include text-sm-regular;
    color: var(--medium-grey);
    text-align: center;
  }
}

.payment-item-list {
  border-top: 1px solid var(--HR);
  border-bottom: 1px solid var(--HR);
  padding: 12px 0;

  .transfer-detail {
    gap: 20px;
    padding: 0;

    .detail-label {
      @include text-base-regular;
      color: var(--medium-grey);
    }

    .detail-value {
      @include text-base-medium;
      color: var(--charcoal);
      text-align: right;
    }

    & + .transfer-detail {
      margin-top: 12px;
    }
  }
}
