@import "./typography-mixins.scss";
@import "./mixins.scss";

.app-utility-modal {
  .MuiModal-backdrop {
    background: linear-gradient(0deg, rgba(33, 37, 41, 0.32) 0%, rgba(33, 37, 41, 0.32) 100%), rgba(33, 39, 55, 0.5);
    backdrop-filter: blur(15px);
  }

  .MuiPaper-root {
    border-radius: var(--rounded-lg);
    background: var(--white);
    box-shadow: var(--modal-drop-shadow);
    margin: 0;
    overflow-x: hidden;
    max-width: 90vw;
    max-width: 90dvw;
    max-height: calc(100vh - 60px);
    max-height: calc(100dvh - 60px);

    .modal-skeleton {
      margin: 0 24px 24px;
    }

    .modal-promo-section {
      padding: 8px 16px;
      background: var(--primary-400);

      @include text-sm-regular;
      text-align: center;

      .arrow {
        width: 6px;
        height: 6px;
        margin: 0 6px;
      }
    }

    .modal-dialog-header {
      @include flex-space-between;
      padding: 16px 24px;
      gap: 16px;
      background: var(--white);
      position: sticky;
      top: 0;
      z-index: 1;

      .title {
        @include text-xl-semibold;
        line-height: 28px;
        color: var(--charcoal);
        text-align: center;
        white-space: pre-line;
        margin-bottom: 0;
      }

      .go-back-button,
      .close-button {
        @include flex-center;
        height: 36px;
        width: 36px;
        padding: 4px;
        border-radius: var(--rounded-full);

        &:not(:disabled):hover {
          @include background-opacity(var(--HR), 40%);
        }

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .modal-dialog-content {
      margin: 0 24px;

      &.include-mb {
        margin-bottom: 24px;
      }

      > span {
        display: block;
      }
    }

    .modal-image,
    .modal-image-carousel {
      width: 100%;
    }

    .modal-status-icon {
      display: block;
      height: 80px;
      width: 80px;
      margin: 8px auto;

      &.alert-error {
        path.color-fillable {
          fill: var(--error-red);
        }
      }
    }

    .modal-image-carousel {
      margin-bottom: 52px !important;

      .carousel-control-prev,
      .carousel-control-next {
        display: none;
      }

      img {
        width: 100%;
      }

      .carousel-indicators {
        bottom: -34px;
        margin-bottom: 0;

        button {
          height: 10px;
          width: 10px;
          border: none;
          border-radius: var(--rounded-full);
          background-color: var(--HR);

          &.active {
            opacity: 1;
            background-color: var(--charcoal);
            width: 20px;
          }
        }
      }
    }

    .modal-status-title {
      @include text-2xl-semibold;
      color: var(--charcoal);
      white-space: pre-line;
    }

    .modal-billboard-text {
      @include text-5xl-semibold;
      color: var(--charcoal);
      text-align: center;
    }

    .modal-content-text {
      @include text-base-regular;
      color: var(--charcoal);
      white-space: pre-line;

      &.highlight {
        @include text-base-medium;
      }
    }

    .action-container {
      flex-direction: column;
      row-gap: 16px;
      padding: 24px;
      background: var(--white);
      position: sticky;
      bottom: 0;
      z-index: 1;

      &.split-equally {
        flex-direction: row-reverse;
        gap: 16px;

        > * {
          flex: 1 0;
        }
      }
    }
  }

  &.tooltip-dialog .MuiPaper-root,
  &.info-dialog .MuiPaper-root,
  &.status-dialog .MuiPaper-root,
  &.prompt-dialog .MuiPaper-root {
    width: 420px;
  }

  &.form-dialog .MuiPaper-root {
    width: 480px;
  }

  &.flex-dialog .MuiPaper-root,
  &.step-by-step-dialog .MuiPaper-root {
    width: 520px;
  }

  &.flex-dialog.full-modal-width .MuiPaper-root,
  &.fullscreen-dialog .MuiPaper-root {
    width: 588px;
  }

  &.rendering-page-content .MuiPaper-root {
    min-height: 500px;

    .modal-skeleton {
      flex: 1 0;
      display: flex;
      flex-direction: column;

      .image-skeleton {
        min-height: 200px;
        flex: 1 0;
      }
    }

    .content {
      @include flex-space-between;

      .bottom-container.stick-to-modal-bottom {
        position: sticky;
        bottom: 0;
        background: var(--white);
        margin: -24px 0;
        padding: 24px 0;
      }
    }

    .loading-content {
      @include flex-center;

      .MuiCircularProgress-root {
        height: 24px !important;
        width: 24px !important;
        color: currentColor;
      }
    }

    .content,
    .loading-content {
      flex: 1 0;
      margin: 0 24px 24px;
      min-height: auto;

      flex-direction: column;
      gap: 24px;

      & > * {
        width: 100%;
      }
    }
  }

  &.status-dialog .MuiPaper-root,
  &.prompt-dialog .MuiPaper-root {
    .modal-promo-section,
    .modal-dialog-header {
      display: none;
    }

    .modal-dialog-content {
      margin-top: 24px;

      & > * {
        text-align: center;
      }
    }
  }
}

.app-utility-modal.hide-header {
  .MuiPaper-root {
    .modal-dialog-header {
      display: none;
    }
  }
}

.app-utility-modal.hide-back-button {
  .MuiPaper-root {
    .modal-dialog-header .go-back-button {
      display: none;
    }
  }
}

.app-utility-modal.position-close-button-over-content {
  .MuiPaper-root {
    .modal-dialog-header {
      position: relative;
      padding: 0;

      & > *:not(.close-button) {
        display: none;
      }

      & .close-button {
        position: absolute;
        top: 24px;
        right: 24px;
        z-index: 2;
      }
    }
  }
}

@include mq(629, max) {
  .app-utility-modal {
    .MuiPaper-root {
      width: 100vw !important;
      width: 100dvw !important;
      max-width: 100vw;
      max-width: 100dvw;
      max-height: 90vh;
      max-height: 90dvh;
      margin: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: var(--rounded-4xl) var(--rounded-4xl) 0 0;

      .modal-billboard-text {
        @include text-4xl-semibold;
      }

      .modal-skeleton {
        margin: 0 16px 24px;
      }

      .modal-dialog-header {
        padding: 16px;
      }

      .modal-dialog-content {
        margin: 0 16px;
      }

      .action-container {
        padding: 24px 16px;
      }
    }

    &.flex-dialog:not(.no-full-screen) .MuiPaper-root,
    &.step-by-step-dialog .MuiPaper-root,
    &.fullscreen-dialog .MuiPaper-root {
      top: 0;
      height: 100vh;
      height: 100dvh;
      max-height: 100vh;
      max-height: 100dvh;
      border-radius: 0;

      .modal-image,
      .modal-image-carousel {
        flex: 1 0;
      }
    }

    &.rendering-page-content .MuiPaper-root {
      .content,
      .loading-content {
        margin: 0 16px 24px;
      }
    }
  }

  .app-utility-modal.position-close-button-over-content {
    .MuiPaper-root {
      .modal-dialog-header {
        & .close-button {
          top: 16px;
          right: 16px;
        }
      }
    }
  }
}
